// spell-checker: ignore  Busqueda, categoria, Categoria, Categ


/**
 * @private
 * Sends an event to Google Analytics
 * @param {String} func
 * @param {String} name
 * @param {Object} params
 * @returns {Void}
 */
function _sendEvent(func, name, params) {
    window.gtag("event", name, params);

    // Log the event in the console for debugging
    const url = window.location.href;
    if (url.includes("localhost") || url.includes("benvida.local") || url.includes("benvida.ar")) {
        console.log({ func, name, params });
    }
}

/**
 * @private
 * Calculates the scroll percentage
 * @param {Number} currentScroll
 * @param {Number} totalScroll
 * @returns {Number}
 */
function _getScrollPercent(currentScroll, totalScroll) {
    return Math.round((currentScroll / totalScroll) * 100);
}

/**
 * @private
 * Calculates the seconds open
 * @param {Number} openTime
 * @returns {Number}
 */
function _getOpenSeconds(openTime) {
    return Math.round((Date.now() - openTime) / 1000);
}

/**
 * @private
 * Gets a single item for the event
 * @param {Object} product
 * @param {String} listName
 * @param {Number} listID
 * @returns {Object}
 */
function _getItems(product, listName, listID) {
    const products = Array.isArray(product) ? product : [ product ];
    return {
        currency : "ARS",
        value    : products.reduce((total, { price, quantity }) => total + ((quantity || 1) * price), 0),
        items    : products.map((product, index) => ({
            index          : index + 1,
            item_id        : product.productID,
            item_name      : product.name,
            item_brand     : product.laboratoryName,
            item_category  : product.macroCategoryName,
            item_category2 : product.categoryName,
            item_category3 : product.subCategoryName,
            item_list_name : listName,
            item_list_id   : listID,
            currency       : "ARS",
            price          : product.price,
            discount       : 0,
            quantity       : product.quantity || 1,
        })),
    };
}



/**
 * Sends a search event
 * @param {String} searchValue
 * @returns {Void}
 */
function search(searchValue) {
    // Old custom event
    _sendEvent("search", "Busqueda", {
        Busqueda : searchValue,
    });

    // New recommended event
    _sendEvent("search", "search", {
        search_term : searchValue,
    });
}



/**
 * Goes to a Space from the Spaces slider
 * @param {{storeSpaceID: Number, name: String}} space
 * @returns {Void}
 */
function gotoSpaceFromSpaces(space) {
    // Old custom event
    _sendEvent("gotoSpaceFromSpaces", "Menu_Categoria", {
        Click_Categoria : space.name,
    });

    // New recommended event
    _sendEvent("gotoSpaceFromSpaces", "select_content", {
        content_type : "space",
        content_id   : space.storeSpaceID,
    });
}

/**
 * Goes to a Space from the Title in the Home
 * @param {{storeSpaceID: Number, name: String}} space
 * @returns {Void}
 */
function gotoSpaceFromTitle(space) {
    // Old custom event
    _sendEvent("gotoSpaceFromTitle", "SliderCat_home", {
        Click_Titulo_Slider : space.name,
    });

    // New recommended event
    _sendEvent("gotoSpaceFromTitle", "select_content", {
        content_type : "space",
        content_id   : space.storeSpaceID,
    });
}

/**
 * Goes to a Space from the "View all" in the Home
 * @param {{storeSpaceID: Number, name: String}} space
 * @returns {Void}
 */
function gotoSpaceFromViewAll(space) {
    // Old custom event
    _sendEvent("gotoSpaceFromViewAll", "SliderCat_home", {
        Click_VerTodo_Slider : space.name,
    });

    // New recommended event
    _sendEvent("gotoSpaceFromTitle", "select_content", {
        content_type : "ver-todo",
        content_id   : space.storeSpaceID,
    });
}

/**
 * Goes to a Space from a Banner
 * @param {Number} storeBannerID
 * @param {String} spaceName
 * @returns {Void}
 */
function gotoSpaceFromBanner(storeBannerID, spaceName) {
    // Old custom event
    _sendEvent("gotoSpaceFromBanner", "Home_Banner", {
        Click_Banner : spaceName,
    });

    // New recommended event
    _sendEvent("gotoSpaceFromBanner", "select_content", {
        content_type : "banner-home",
        content_id   : storeBannerID,
    });
}

/**
 * Goes to a Banner url
 * @param {String} url
 * @returns {Void}
 */
function gotoBannerUrl(url) {
    // Old custom event
    _sendEvent("gotoBannerUrl", "Home_Banner", {
        Click_Banner : url,
    });
}



/**
 * Scrolls in a Space
 * @param {Number} currentScroll
 * @returns {Void}
 */
function scrollInSpace(currentScroll) {
    // Old custom event
    const totalScroll = document.body.offsetHeight - window.innerHeight;
    _sendEvent("scrollInSpace", "Menu_Categoria", {
        Scroll_Categoria : _getScrollPercent(currentScroll, totalScroll),
    });
}

/**
 * Scrolls in a Home Space
 * @param {Number} currentScroll
 * @param {Number} totalScroll
 * @returns {Void}
 */
function scrollInHomeSpace(currentScroll, totalScroll) {
    // Old custom event
    _sendEvent("scrollInHomeSpace", "SliderCat_home", {
        Scroll_Slider : _getScrollPercent(currentScroll, totalScroll),
    });
}



/**
 * Opens the Product Dialog
 * @param {Object} product
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function openProductDialog(product, listName, listID) {
    // Old custom event
    _sendEvent("openProductDialog", "Modal_Item", {
        OpenModal  : product.name,
        CategModal : product.categoryName,
        ValorModal : product.price,
    });

    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("openProductDialog", "view_item", params);
}

/**
 * Closes the Product Dialog
 * @param {Object} product
 * @param {Number} openTime
 * @returns {Void}
 */
function closeProductDialog(product, openTime) {
    // Old custom event
    _sendEvent("closeProductDialog", "Modal_Item", {
        CerrarModal : 1,
        OpenModal   : product.name,
        CategModal  : product.categoryName,
        ValorModal  : product.price,
        TimeModal   : _getOpenSeconds(openTime),
    });
}

/**
 * Adds a Product in the List
 * @param {Object} product
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function addProductInList(product, listName, listID) {
    // Old custom event
    _sendEvent("addProductInList", "Item_PagCat", {
        ClickMas_ItemCat : 1,
        Id_Categoria     : product.categoryName,
        Click_ItemCat    : product.name,
        Valor_ItemCat    : product.price,
    });

    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("addProductInList", "add_to_cart", params);
}

/**
 * Removes a Product in the List
 * @param {Object} product
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function removeProductInList(product, listName, listID) {
    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("removeProductInList", "remove_from_cart", params);
}

/**
 * Adds a Product in the Dialog
 * @param {Object} product
 * @param {Number} openTime
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function addProductInDialog(product, openTime, listName, listID) {
    // Old custom event
    _sendEvent("addProductInDialog", "Modal_Item", {
        CMasModal  : 1,
        OpenModal  : product.name,
        CategModal : product.categoryName,
        ValorModal : product.price,
        TimeModal  : _getOpenSeconds(openTime),
    });

    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("addProductInDialog", "add_to_cart", params);
}

/**
 * Removes a Product in the Dialog
 * @param {Object} product
 * @param {Number} openTime
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function removeProductInDialog(product, openTime, listName, listID) {
    // Old custom event
    _sendEvent("removeProductInDialog", "Modal_Item", {
        CMenosModal : -1,
        OpenModal   : product.name,
        CategModal  : product.categoryName,
        ValorModal  : product.price,
        TimeModal   : _getOpenSeconds(openTime),
    });

    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("removeProductInDialog", "remove_from_cart", params);
}



/**
 * Opens the Cart
 * @param {Object[]} items
 * @param {String}   listName
 * @param {Number}   listID
 * @returns {Void}
 */
function openCart(items, listName, listID) {
    // Old custom event
    if (!items.length) {
        _sendEvent("openCart", "Modal_Carrito", {
            Open_Carrito : 1,
        });
    } else {
        _sendEvent("openCart", "Modal_Carrito", {
            Open_Carrito    : 1,
            Items_Carrito   : items.length,
            Detalle_Carrito : items.map(({ name }) => name).join(", "),
            Valor_Carrito   : items.reduce((total, { price, quantity }) => total + (quantity * price), 0),
        });
    }

    // New recommended event
    const params = _getItems(items, listName, listID);
    _sendEvent("openCart", "view_cart", params);
}

/**
 * Adds a Product in the Cart
 * @param {Object} product
 * @param {Number} quantity
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function addProductInCart(product, quantity, listName, listID) {
    // Old custom event
    _sendEvent("editProductInCart", "Modal_Carrito", {
        Detalle_Carrito        : product.name,
        EditCant_Items_Carrito : quantity,
    });

    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("addProductInCart", "add_to_cart", params);
}

/**
 * Removes a Product in the Cart
 * @param {Object} product
 * @param {Number} quantity
 * @param {String} listName
 * @param {Number} listID
 * @returns {Void}
 */
function removeProductInCart(product, quantity, listName, listID) {
    // Old custom event
    if (quantity === 0) {
        _sendEvent("removeProductInCart", "Modal_Carrito", {
            Edit_Items_Carrito : product.name,
        });
    } else {
        _sendEvent("removeProductInCart", "Modal_Carrito", {
            Detalle_Carrito        : product.name,
            EditCant_Items_Carrito : quantity,
        });
    }

    // New recommended event
    const params = _getItems(product, listName, listID);
    _sendEvent("removeProductInCart", "remove_from_cart", params);
}

/**
 * Confirms the Cart
 * @param {Object[]} items
 * @param {String}   listName
 * @param {Number}   listID
 * @returns {Void}
 */
function confirmCart(items, listName, listID) {
    // Old custom event
    _sendEvent("confirmCart", "Modal_Carrito", {
        Confirmar_Carrito : 1,
        Items_Carrito     : items.length,
        Detalle_Carrito   : items.map(({ name }) => name).join(", "),
        Valor_Carrito     : items.reduce((total, { price, quantity }) => total + (quantity * price), 0),
    });

    // New recommended event
    const params = _getItems(items, listName, listID);
    _sendEvent("confirmCart", "begin_checkout", params);
}

/**
 * Cancels the Cart
 * @param {Number} openTime
 * @returns {Void}
 */
function cancelCart(openTime) {
    // Old custom event
    _sendEvent("closeCart", "Modal_Carrito", {
        Cancelar_Carrito : 1,
        Time_Carrito     : _getOpenSeconds(openTime),
    });
}

/**
 * Closes the Cart
 * @param {Number} openTime
 * @returns {Void}
 */
function closeCart(openTime) {
    // Old custom event
    _sendEvent("closeCart", "Modal_Carrito", {
        Cerrar_Carrito : 1,
        Time_Carrito   : _getOpenSeconds(openTime),
    });
}




// The public API
export default {
    search,

    gotoSpaceFromSpaces,
    gotoSpaceFromTitle,
    gotoSpaceFromViewAll,
    gotoSpaceFromBanner,
    gotoBannerUrl,

    scrollInSpace,
    scrollInHomeSpace,

    openProductDialog,
    closeProductDialog,

    addProductInList,
    removeProductInList,
    addProductInDialog,
    removeProductInDialog,

    openCart,
    addProductInCart,
    removeProductInCart,
    confirmCart,
    cancelCart,
    closeCart,
};
