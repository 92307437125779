import React                from "react";
import PropTypes            from "prop-types";
import Styled               from "styled-components";
import Analytics            from "Utils/Analytics";
import Store                from "Utils/Store";
import Utils                from "Utils/Utils";

// Components
import Dialog               from "Components/Utils/Dialog";
import Html                 from "Components/Utils/Html";
import Icon                 from "Components/Utils/Icon";



// Styles
const Container = Styled(Dialog)`
    width: 600px;
    min-height: 50px;
    max-height: calc(100dvh - 24px - 24px - 60px);

    @media (max-width: 600px) {
        margin-bottom: 60px;
    }
`;

const Inside = Styled.div`
    display: flex;
    gap: 24px;

    @media (max-width: 600px) {
        flex-direction: column;
        align-items: center;
    }
`;

const Image = Styled.img`
    width: 240px;
    height: 240px;
    object-fit: cover;
`;

const Content = Styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    flex-grow: 2;
    width: 100%;
`;

const Title = Styled.h3`
    margin: 0;
    font-size: 20px;
`;

const Laboratory = Styled.p`
    margin: 0;

    b { margin-right: 4px; }
`;

const Description = Styled(Html)`
    margin: 8px 0 0 0;
    color: rgb(24, 45, 103);
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-word;

    :empty {
        display: none;
    }
`;

const Footer = Styled.footer`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 8px;
`;

const Buttons = Styled.div`
    display: flex;
    background-color: var(--store-button-bg);
    color: var(--store-button-text);
    border-radius: var(--border-radius-small);
    margin-top: 12px;

    .icon {
        padding: 6px 12px;
        font-size: 16px;
        cursor: pointer;
    }
    div {
        padding: 6px 0;
    }
`;

const Price = Styled(Html)`
    font-size: 18px;
    font-weight: bold;
`;



/**
 * The Product Dialog
 * @param {Object} props
 * @returns {React.ReactElement}
 */
function Product(props) {
    const { open } = props;

    const { currentProduct } = Store.useState();
    const { listName, listID } = Store.useList(currentProduct.storeSpaceID);

    const [ quantity, update ] = Store.useAddCart(currentProduct);
    const showProduct          = Store.useShowProduct();
    const getText              = Store.useText();


    // The Current State
    const [ openTime, setOpenTime ] = React.useState(0);


    // Handles the Open Dialog
    React.useEffect(() => {
        if (open) {
            setOpenTime(Date.now());
        }
    }, [ open ]);

    // Handles the Add
    const handleAdd = () => {
        update(1);
        Analytics.addProductInDialog(currentProduct, openTime, listName, listID);
    };

    // Handles the Remove
    const handleRemove = () => {
        if (quantity > 0) {
            update(-1);
            Analytics.removeProductInDialog(currentProduct, openTime, listName, listID);
        }
    };

    // Handles the Close
    const handleClose = () => {
        showProduct();
        Analytics.closeProductDialog(currentProduct, openTime);
    };

    // Gets the Price
    const price = React.useMemo(() => {
        if (currentProduct.showProductPrice) {
            return Utils.formatPrice(currentProduct.price);
        }
        return "";
    }, [ currentProduct.showProductPrice, currentProduct.price ]);


    // Do the Render
    if (!open) {
        return <React.Fragment />;
    }
    return <Container onClose={() => handleClose()}>
        <Inside>
            <Image
                src={currentProduct.imageUrl}
                alt={currentProduct.name}
            />
            <Content>
                <Title>{currentProduct.name}</Title>
                <Laboratory>
                    <b>{getText("laboratory")}</b>
                    {currentProduct.laboratoryName}
                </Laboratory>
                <Description
                    content={currentProduct.description}
                    addBreaks
                />

                <Footer>
                    <Price>{price}</Price>
                    <Buttons>
                        <Icon icon="minus" onClick={() => handleRemove()} />
                        <div>{quantity}</div>
                        <Icon icon="plus" onClick={() => handleAdd()} />
                    </Buttons>
                </Footer>
            </Content>
        </Inside>
    </Container>;
}

/**
 * The Property Types
 * @typedef {Object} propTypes
 */
Product.propTypes = {
    open : PropTypes.bool.isRequired,
};

export default Product;
